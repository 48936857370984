@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.CookieConsent {
	display: block;
	position: fixed;
	font-size: 0.9em;
	width: 100%;
	bottom: 0;
	color: $text-dark;
	background: lightblue;
	height: 0;
	-webkit-animation: slideIn 0.5s linear 0s 1 forwards;
	animation: slideIn 0.5s linear 0s 1 forwards;
}

.CookieConsent-text {
	padding: 0.85em;
	text-align: center;
}

.CookieConsent-button {
	display: block;
	width: 2.65em;
	font-size: 1.1em;
	font-weight: bold;
	color: $text-dark;
	background-color: white;
	text-transform: uppercase;
	padding: 0.2em 0;
	margin: auto;
	text-align: center;
}

@-webkit-keyframes slideIn {
	0% {height: 0;}
	100% {height: 7em;}
}
@keyframes slideIn {
	0% {height: 0;}
	100% {height: 7em;}
}
