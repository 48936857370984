@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
	.CardPreview {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(black, 0.5);
	}

	.CardPreview-content {
		position: absolute;
		top: calc(50vh - 12.5em);
		left: calc(50% - 10em);
		width: 20em;
		height: 25em;
		padding: 1em;
		background-color: #FFF6F6;
	}

	.CardPreview-closeBtn {
		position: absolute;
		top: 0.75em;
		right: 0.75em;
		width: 1.25em;
		height: 1.25em;
		background-image: url('../../../assets/images/icon-close.svg');
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		cursor: pointer;
	}


	.CardPreview-cardContainer {
		position: relative;
		margin: 0em auto;
		width: 16em;
		height: 16.5em;
		// padding: 0 4.3% calc(3.7% + 0.5em) 0;
		will-change: transform;
		background-image: url('../../../assets/images/decks/pink-back.svg'), url('../../../assets/images/decks/pink-deck.svg'), ;
		background-size: calc(96.2%) auto, 100% auto;
		background-position: top left, top left;
		background-repeat: no-repeat, no-repeat;
		padding: 0 3% calc(3.7% + 0.3em) 0;
	}