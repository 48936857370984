@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Tags {
	font-size: 1vw;
	min-height: 100vh;
	color: black;
	background: #d4d2f8;
	padding: 5em 2em 2em 2em;
	.Tags-backBtn {
		position: absolute;
		top: 1em;
		left: 2em;
		background-color: white;
		padding: 0.5em 0.8em;
		border-radius: 0.3em;
		font-weight: bold;
		cursor: pointer;
		&:hover {background-color: darken(white, 5%);}
	}
	
	.Tags-card {
		height: 1.5em;
		overflow: hidden;
	}
	
	.Tags-newTagBtn {
		display: inline-block;
		background-color: white;
		padding: 0.5em 0.8em;
		border-radius: 0.3em;
		font-weight: bold;
		margin-top: 2em;
		cursor: pointer;
		&:hover {background-color: darken(white, 5%);}
		&--saving {
			background-image: url('../../../assets/images/icon-loading.svg');
			background-size: auto 80%;
			background-position: right center;
			background-repeat: no-repeat;
		}
	}
	
	.Tags-title {
		font-size: 2em;
		text-transform: uppercase;
		font-weight: bold;
		margin-bottom: 1em;
	}
	
	.Tags-section {
		margin-bottom: 4em;
	}
	
	
	.Tags-header {
		>div {
			font-weight: bold;
			background-image: none;
		}
	}
	
	.Tags-row {
		margin: 0.25em 0;
		cursor: pointer;
		&:hover {background-color: rgba(white, 0.2);}
	}
	.Tags-tagId,
	.Tags-tagTitle,
	.Tags-tagCards {
		display: inline-block;
		vertical-align: top;
		width: 10em;
	}
	.Tags-tagCards {
		width: 30em;
	}


}

