@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Admin {
	font-size: 1vw;
	height: 100vh;
	min-height: 100vh;
	color: black;
	background: #d4d2f8;
	padding: 1em 2em;
}

.Admin-title {
	font-size: 2em;
	margin-bottom: 1em;
}


.Admin-logout {
	position: absolute;
	top: 1em;
	right: 2em;
	background-color: white;
	padding: 0.5em 0.8em;
	border-radius: 0.3em;
	font-weight: bold;
	cursor: pointer;
	&:hover {background-color: darken(white, 5%);}
}

.Admin-text {
	font-size: 1.25em;
	margin-bottom: 1em;
}

.Admin-button {
	display: inline-block;
	width: 10em;
	background-color: white;
	padding: 0.5em 0.8em;
	margin: 0.5em 0;
	border-radius: 0.3em;
	font-weight: bold;
	cursor: pointer;
	&:hover {background-color: darken(white, 5%);}
}