@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.TagPopup {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(black, 0.5);
	padding: 5em 20em;
}

.TagPopup-content {
	position: relative;
	width: 100%;
	height: 100%;
	background: white;
	border-radius: 0.33em;
	padding: 2em;
	overflow: auto;
}

.TagPopup-backBtn {
	position: absolute;
	top: 1em;
	right: 1em;
	width: 1.5em;
	height: 1.5em;
	background-image: url('../../../assets/images/icon-close.svg');
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	cursor: pointer;
}

.TagPopup-tagId {
	font-size: 1.5em;
	font-weight: bold;
	margin-bottom: 0.5em;
}

.TagPopup-saveTagBtn {
	display: inline-block;
	background-color: red;
	opacity: 0.3;
	padding: 0.5em 1.5em;
	border-radius: 0.3em;
	font-weight: bold;
	margin-bottom: 2em;
	cursor: not-allowed;
	&--active {
		opacity: 1;
		color: white;
		background-color: green;
		cursor: pointer;
		&:hover {background-color: darken(green, 5%);}
	}
	&--saving {
		background-image: url('../../../assets/images/icon-loading.svg');
		background-size: auto 80%;
		background-position: right center;
		background-repeat: no-repeat;
	}
}

.TagPopup-deleteTagBtn {
	display: inline-block;
	color: white;
	background-color: red;
	cursor: pointer;
	padding: 0.5em 1.5em;
	border-radius: 0.3em;
	font-weight: bold;
	margin-bottom: 2em;
}


.TagPopup-section {
	font-size: 1.125em;
	margin-bottom: 2em;
	input {
		width: 20em;
		line-height: 1;
		padding: 0.25em;
		height: 1.5em;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
  	-webkit-appearance: none;
  	margin: 0;
	}
	input[type=number] {
  	-moz-appearance: textfield;
	}
}

.TagPopup-input {
	&.red {
		border: 1px solid red;
		&:active,
		&:focus {
			outline: none !important;
			border: 1px solid red;
		}
	}
	
}

.TagPopup-sectionTitle {
	font-size: 1.125em;
	font-weight: bold;
	text-transform: uppercase;
}

.TagPopup-areaInfo {
	margin: 0.35em 0;
	span {
		display: inline-block;
		vertical-align: top;
		font-weight: bold;
		width: 8em;
	}
}