@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Stats {
	font-size: 1vw;
	min-height: 100vh;
	color: black;
	background: #d4d2f8;
	padding: 5em 2em 2em 2em;

	.Stats-backBtn {
		position: absolute;
		top: 1em;
		left: 2em;
		background-color: white;
		padding: 0.5em 0.8em;
		border-radius: 0.3em;
		font-weight: bold;
		cursor: pointer;
		&:hover {background-color: darken(white, 5%);}
	}

	.Stats-title {
		font-size: 2em;
		text-transform: uppercase;
		font-weight: bold;
		margin-bottom: 1em;
	}

	.Stats-players {
		font-size: 1.1em;
		span {
			font-weight: bold;
		}
	}

	.Stats-downloadBtn {
		display: inline-block;
		width: 10em;
		background-color: white;
		padding: 0.5em 0.8em;
		margin-top: 3em;
		border-radius: 0.3em;
		font-weight: bold;
		cursor: pointer;
		&:hover {background-color: darken(white, 5%);}
	}
}