@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Card {
	position: relative;
	width: 100%;
	height: 100%;
	will-change: transform;
	&.animate {
		animation: animateCardLeave 0.5s ease-out 0s forwards;
		.Card-content {
			animation: animateCardRotate 0.5s ease-out 0s forwards;
		}
		&.right {
			animation: animateCardLeaveRight 0.5s ease-out 0s forwards;
			.Card-content {
				animation: animateCardRotateRight 0.5s ease-out 0s forwards;
			}
		}
	}
}

.Card-content {
	position: relative;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	will-change: transform;
	color: $text-dark;
	transform-origin: bottom center;
	p {margin: 0;}	
	@include no-select();
	&--intro-5 {
		.Card-front.Card-front--intro {
			padding: 4em 2em 2em 2em;
		}
	}
}

.Card-front,
.Card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: white;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	will-change: transform, opacity;
	backface-visibility: hidden;
	overflow: hidden;
	@include no-select();
}
.Card-front {
	border: 0.02em solid rgba(black, 0.5);
	&--feedback,
	&--mission,
	&--portal {
		padding: 1.5em 2em;
		text-align: center;
		@include flex('center', 'center');
		span {
			text-align: center;
			display: block;
			text-transform: uppercase;
			font-weight: bold;
		}
	}
	&--intro {
		display: block;
		padding: 7em 2em 2em 2em;
		text-align: center;
		background-color: white;
	}
	&--gameover {
		color: $text-light;
		background-color: #3329FD;
		padding: 2em;
		.Card-okBtn {
			text-align: center;
			color: $text-dark;
			background-color: white;
			width: 5em;
			font-weight: bold;
		}
	}
}
.Card-back {
	background-color: transparent;
	background-size: contain;
	background-position: top left;
	background-repeat: no-repeat;
	background-image: url('../../../assets/images/decks/pink-back.svg');
}

.Card-answer {
	position: absolute;
	top: 0;
	width: calc(100% + 1em);
	color: $text-light;
	font-weight: bold;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 0;
	transform-origin: bottom right;
	text-align: right;
	@include no-select();
	&--left {
		left: -1em;
		padding: 0 1em 1em 7em;
	}
	&--right {
		text-align: left;
		transform-origin: bottom left;
		right: -1em;
		padding: 0 7em 1em 1em;
	}
}
.Card-answerText {
	font-size: 0.9em;
	line-height: 1.1;
	@include no-select();
}

.Card-title {
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 1em;
}


.Card-okBtn {
	width: 3em;
	padding: 0.25em 0;
	text-transform: uppercase;
	text-align: center;
	border: 1px solid black;
	margin: 2em auto 1em auto;
	background-color: white;
	@include no-select();
}