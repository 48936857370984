@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Survey {
	@include defaultPage();
	height: auto;
	position: absolute;
	top: 0;
	padding: 1em 1.5em;
	color: $text-dark;
	background: #F5FBFF;
	&--intro,
	&--textarea {
		@include flex('center', 'center');
	}
	opacity: 0;
	@include animateFadeIn();
	&.flowers {
		background-image: url('../../assets/images/flower1.svg'), url('../../assets/images/flower2.svg');
		background-size: 100% auto, 100% auto;
		background-position: bottom left, top right;
		background-repeat: no-repeat;
	}
	&.exit {
		.Survey-option {
			padding: 0.45em;
			white-space: normal;
		}
	}
	&--tag-choose {
		padding-top: 5.5em;
	}
}


.Survey-image {
	width: 100%;
	padding-bottom: 0.85em;
	img {
		width: 100%;
		border: 1px solid rgba(black, 0.25);
	}
}

.Survey-title {
	font-weight: 700;
	text-align: left;
}

.Survey-subtitle {
	font-size: 0.85;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 0.5em;
}

.Survey-progress {
	width: 100%;
	text-align: right;
	font-size: 0.55em;
	color: #585858;
}

.Survey-options {
	position: relative;
	margin-bottom: 1em;
	padding: 0 1em;
	&--1 {
		.Survey-option {
			min-width: 10em;
			max-width: 80%;
			float: left;
			&:nth-child(odd) {
				float: right;
				// margin-left: calc(100% - 10.85em);
			}
		}
	}
	&--2 {
		.Survey-option {
			width: 7em;
			&:nth-child(3n+1) {
				width: 10em;
				margin: auto auto 0.65em auto;
			}
			&:nth-child(3n+0) {
				display: inline-block;
				margin-left: calc(100% - 14em);
			}
			&:nth-child(3n+2) {
				display: inline-block;
			}
		}	
	}
	&.tag {
		@include flex('space-between', 'center');
		.Survey-option {
			width: 6em;
		}
	}
}

.Survey-option {
	line-height: 1;
	padding: 0.45em 0.5em;
	// width: 10.85em;
	text-align: center;
	background-color: white;
	border: 1px solid rgba(black, 0.25);
	white-space: nowrap;
	margin-bottom: 0.65em;
	span {
		font-size: 0.85em;
		color: $text-dark;
	}

	&--selected {
		span {color: $text-light;}
		background-color: #0A23FB;
	}
}

.Survey-textarea {
	width: 100%;
	textarea {
		width: 100%;
		height: 9em;
		resize: none;
		border: 1px solid rgba(black, 0.25);
		font-family: 'Roboto Condensed';
		padding: 0.45em;
	}
}

.Survey-input {
	width: 100%;
	margin-top: 0.5em;
	input,
	input:focus {
		width: 100%;
		border: 1px solid rgba(black, 0.25);
		font-family: 'Roboto Condensed';
		padding: 0.53em;
		font-size: 0.85em;
	}
}

.Survey-buttons {
	text-align: center;
	margin-top: 0.85em;
}

.Survey-button {
	width: 5em;
	line-height: 1;
	padding: 0.45em 0;
	color: $text-dark;
	background-color: white;
	text-transform: uppercase;
	font-weight: bold;
	border: 1px solid rgba(black, 0.25);
	display: inline-block;
}

.Survey-continue {
	margin: auto;
	display: inline-block;
	background-color: white;
	padding: 0.85em 1.3em;
	@include box-shadow(0, 0.15em, 0.3em, 0, rgba(black, 0.5));
}