@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.MainMenu {
	@include defaultPage();
	justify-content: center;
	background-color:#FFF6F7;
	background-image: 
		url('../../assets/images/background-login-top.svg'), 
		url('../../assets/images/background-login-bottom.svg'),
		url('../../assets/images/background-splash-middle.svg');
	background-size: 100% auto;
	background-position: top center, bottom center, center center;
	background-repeat: no-repeat, no-repeat, repeat-y;
	&.slideIn {
		@include animateSlideIn();
	}
	&.popup {
		position: absolute;
		top: 0;
	}

	.MainMenu-content {
		.MainMenu-title {
			width: 100%;
			height: 5.25em;
			background-image: url('../../assets/images/title.svg');
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
			margin-bottom: 1em;
		}
		.MainMenu-playBtn,
		.MainMenu-missionsBtn,
		.MainMenu-surveysBtn,
		.MainMenu-infoBtn {
			width: 8.5em;
			height: 3.5em;
			margin: auto;
			background-size: 8.25em auto;
			background-position: center center;
			background-repeat: no-repeat;
			margin-bottom: 1em;
			cursor: pointer;
		}
		.MainMenu-playBtn,
		.MainMenu-missionsBtn,
		.MainMenu-surveysBtn {
			background-image: url('../../assets/images/button-menu.svg');
			text-align: center;
			padding-top: 0.785em;
			span {
				color: black;
				font-weight: bold;
				font-style: italic;
				font-size: 0.9em;
				text-transform: uppercase;
			}
			&:hover,
			&:active {
				background-image: url('../../assets/images/button-menu-hover.svg');
			}
		}
		.MainMenu-infoBtn {
			width: 3.75em;
			background-size: 3.5em auto;
			background-image: url('../../assets/images/button-info.svg');
			&:hover,
			&:active {
				background-image: url('../../assets/images/button-info-hover.svg');
			}
		}
	}
}

.MainManu-timelog {
	position: absolute;
	bottom: 4em;
	left: 2em;
}

.MainMenu-resetCacheBtn {
	position: absolute;
	bottom: 1em;
	left: 0.75em;
	color: $text-dark;
	background-color: white;
	padding: 0.125em 0.25em;
	@include box-shadow(0, 0, 0.05em, 0.1em, rgba(black, 0.16));
}

.MainMenu-quickStartBtn,
.MainMenu-skipBtn {
	position: absolute;
	bottom: 1em;
	left: calc(50% - 5em);
	color: $text-dark;
	background-color: white;
	padding: 0.125em 0.25em;
	@include box-shadow(0, 0, 0.05em, 0.1em, rgba(black, 0.16));
}
.MainMenu-skipBtn {
	left: calc(50% + 1em);
}

.MainMenu-logoutBtn {
	position: absolute;
	bottom: 1em;
	right: 0.75em;
	color: $text-dark;
	background-color: white;
	padding: 0.125em 0.25em;
	@include box-shadow(0, 0, 0.05em, 0.1em, rgba(black, 0.16));
}